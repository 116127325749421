<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">{{ $t("label.lbl_userSelect") }}</div>
        </v-col>
      </v-row>
    </v-container>

    <!-- 検索項目 -->
    <!-- v-row 1 担当者 始まり -->
    <v-container fluid>
      <v-row class="search-row">
        <div class="search-autocomplete first-search-item">
          <v-autocomplete
            dense
            v-model="pickingUserSelected"
            :items="pickingUserList"
            :label="$t('label.lbl_popDestinationNm')"
            :hint="setPickingUser()"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-autocomplete>
        </div>
      </v-row>
    </v-container>
    <!-- v-row 1 担当者 終わり -->

    <!-- v-row 2 戻るボタン 始まり -->
    <v-container fluid>
      <v-row>
        <div class="display-search-result-row">
          <div class="first-btn-search" style="float: left">
            <v-btn color="primary" id="btn-search" class="api-btn" @click="search">{{
              $t("btn.btn_search")
            }}</v-btn>
            <span class="text-label"> 総件数：{{ totalCount }}件</span>
          </div>

          <div class="btn-search-area" style="float: right">
            <v-btn color="primary" id="btn-search" class="api-btn" @click="close()">
              {{ $t("btn.btn_close") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- v-row 2 戻るボタン 終わり -->

    <!-- v-row 3 データテーブル 始まり -->
    <v-container fluid>
      <v-data-table
        id="listData"
        fixed-header
        :headers="headerItems"
        :items="inputList"
        disable-filtering
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="400px"
      >
        <template v-slot:item.action="{ item }">
          <v-btn small @click="selectPIC(item)" text>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <!-- v-row 3 データテーブル 終わり -->

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="reservationOk"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
export default {
  components: {
    ConfirmDialog,
    SimpleDialog,
    Loading,
  },
  // 親画面から渡される製品コード
  props: {
    pickingInfo: {},
    index: {},
  },
  data: () => ({
    // ダイアログ表示内容
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    // 検索件数
    totalCount: "0",
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
    },
    // ピッキング担当者
    pickingUserSelected: "",
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_userName"),
        value: "name",
        width: "7%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_config"),
        value: "action",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
    // 一覧
    inputList: [],
    // 担当者選択データ
    pickingUserList: [],
    // 担当者結果データ
    pickingitems: [],
  }),

  created() {},

  methods: {
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.pickingUserList.length; i++) {
          this.pickingUserList[i].value = true;
        }
      } else if (!item.check) {
        for (let i = 0; i < this.pickingUserList.length; i++) {
          this.pickingUserList[i].value = false;
        }
      }
    },

    resarvationDelete() {
      this.confirmDialog.screenFlag = false;
      this.confirmDialog.changeFlag = true;
      this.confirmDialog.message = messsageUtil.getMessage("P-STK-002_002_E");
      // this.ConfirmDialog.redMessage = "無償";
      // this.ConfirmDialog.title = "警告";
      this.confirmDialog.isOpen = true;
    },

    reservationOk() {
      let checkDataList = [];
      // 全体配列から被った配列を消去 (選択した値を全消去)
      for (let i = 0; i < this.pickingUserList.length; i++) {
        if (this.pickingUserList[i].value === true) {
          checkDataList.push(this.pickingUserList[i]);
        }
      }
      this.pickingUserList = this.pickingUserList.filter(function (v) {
        return !checkDataList.includes(v);
      });
    },

    // 出荷先リストボックス（業務利用関係者マスタ）
    getUser() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.searchCategoryDiv = "0";
      // 担当者
      if (this.pickingUserSelected) {
        config.params.userSid = this.pickingUserSelected;
      }

      // console.debug("getUser() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUser() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 選択データ
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                });
              });
              this.pickingUserList = list;

              // 表示データ
              const listItem = [];
              jsonData.resIdv.users.forEach((row) => {
                listItem.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                });
              });
              this.pickingitems = listItem;

              resolve(response);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((resolve) => {
            console.error("getUser() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.pickingSelected = "";
      this.inputList = [];
      this.getUser();
    },

    // 選択したロケーション行
    selectPIC(data) {
      this.$emit("setPIC", data);
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setPickingUser() {
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].value == this.pickingUserSelected) {
          return this.inputList[i].name;
        }
      }
    },

    search() {
      let searchList = [];
      for (let i = 0; i < this.pickingitems.length; i++) {
        if (this.pickingUserSelected === this.pickingitems[i].value) {
          searchList.push(this.pickingitems[i]);
          this.inputList = searchList;
        } else if (this.pickingUserSelected === "" || this.pickingUserSelected === null) {
          this.inputList = this.pickingitems;
        }
      }
      // 検索結果の件数
      this.totalCount = this.inputList.length;
    },

    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
    },
  },
  computed: {},
  mounted() {
    // // バリデーションをリセット
    // this.$refs.form.resetValidation();
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
// popup画面の為、本画面ではmargin-leftは別設定
.search-button {
  width: 7rem;
}
.search-result {
  margin: 0 0 0 71%;
}

//データテーブル

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  pointer-events: none;
}

.first-btn-search {
  margin-left: -1.7%;
}
</style>
